<script lang="ts" setup>
import type { HomeBanners } from "@/types";

const { t } = useT();
const { promotions } = useHomePage();

defineProps<{ banner: HomeBanners[number] }>();

const invitePromotion = computed(() => promotions.value?.filter((item) => item.type === "invite")?.[0] || {});
const referralUrl = invitePromotion.value.data?.referralUrl;
</script>
<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:bg-color="banner.background"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
	>
		<template #default>
			<AText class="banner-title" type="base h7-md" :modifiers="['uppercase', 'italic', 'medium']">
				<p v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<div v-if="invitePromotion?.data?.prizeCoins" class="app-banner__desc-money app-banner__desc-money--coins">
				<NuxtImg class="app-banner__desc-ico app-banner__desc-ico--coins" src="/nuxt-img/banners/coins.png" />
				<div class="app-banner__sum app-banner__sum--coins">
					<AText type="h4 h2-md">{{ numberFormat(invitePromotion?.data?.prizeCoins as number) }}</AText>
				</div>
			</div>
			<div v-if="invitePromotion?.data?.prizeEntries" class="app-banner__desc-money app-banner__desc-money--entries">
				<NuxtImg class="app-banner__desc-ico app-banner__desc-ico--entries" src="/nuxt-img/banners/entries.png" />
				<div class="app-banner__sum app-banner__sum--entries">
					<AText type="h4 h2-md">
						{{ numberFormat(invitePromotion?.data?.prizeEntries as number) }} {{ t("For each!") }}
					</AText>
				</div>
			</div>
		</template>
		<template #actions>
			<div class="d-flex flex-column flex-md-row align-items-center align-items-md-end gap-4">
				<MUrl v-if="referralUrl" :url="referralUrl" toast-id="referralModal" />
				<div class="d-flex align-items-center gap-4">
					<AText type="base" :modifiers="['uppercase']">{{ t("or") }}</AText>
					<MSocialShare v-if="referralUrl" :referralUrl="referralUrl" />
				</div>
			</div>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.share-icon {
	font-size: 20px;
}

:deep(.content) {
	max-width: 250px;
}

:deep(.m-url) {
	@include media-breakpoint-down(md) {
		align-items: center;
	}
}
</style>
